import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'
import BG from '../../public/images/main-bg.png'
import BodyBG from '../../public/images/bg.jpg'
import bgLight from '../../public/images/light-bg.jpg'
import Investment from '../../public/images/Investment.png'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`

  :root {
    --bg-image-dark: url(${BodyBG.src});
    --bg-image-light: url(${bgLight.src});
    --page-bg-image: var(--bg-image-dark);
    --current-theme: 'dark';
    --heading-color: white;
    --text-color: white;
    --btn-color: #3580F1;
    --checkbox-color: #031118;
    --checkbox-border: #3580f1;
    --yellow-text: #fcbf31;
    --footer-input: none;
    --place-holder: #fff;
    --input-color: #dedede;
    --pancake-toggle: #666666;
    --btn-toggle: #041823;
    --btn--border: #806926;
    --modal-bg: linear-gradient(180deg,#062b3f 0%,#000000 100%);
    --chart-text: #3580F1;
    --swap-input: #1e1e1e;
    --copy-text: #fff;
    --user-head: #3580F1;
    --green-head: #fff;
    --card-bg: #062738;
    --footer-bg: linear-gradient(89.89deg,rgba(2,16,23,0.75) 1.07%,rgba(4,26,38,0.75) 99.92%);
    --tab-Bg: '#020E15';
    --tab-color: #fff;
    --list-bg: rgba(5,37,54,0.87);
    --list-border: rgba(4, 26, 38, 0.62);
    --liquidity-box: #1E1E1E;
    --liquidity-border: #252525;
    --per-box: linear-gradient(90.46deg,#3580F1 0.31%,rgba(6,43,63,0) 103.84%);
    --per-border: #327AE5;
    --togg-bg: #327AE5;
    --liq-box: linear-gradient(89.9deg, #3580F1 0.93%, #09172D 99.92%);
    --liq-border: #327AE5;
    --grey-text: #AAAAAA;
    --help-bg: #052B4C;
    --copy-right: #3580f1;
    --select-coin: #1e1e1e;
    --btn-border: #806926;
    --home-banner-dark-image: inline-block;
    --home-banner-light-image: none;
  }

  * {
    font-family: 'Poppins', sans-serif;
  }
  .learnLink {
    &:hover {
      text-decoration: underline;
    }
  }
  body {
    background-image: var(--page-bg-image);
    background-size: cover;
    margin: 0;
    padding: 0;

    img {
      height: auto;
      max-width: 100%;
    }
  }
 a {
  text-decoration: none;
  &:hover {
    text-decoration: none ;
  }
 }
  .Footer-list {
    display : flex;
    align-items: center;
    li {
      list-style: none;
      color: #fff;
      &:first-child {
        
      }
    }
  }
  .copy-text {
    color: var(--copy-text) !important;
  }
  #home-9 .custom-row:nth-child(2) {
    margin-bottom: 0px !important;
  }
  .btns-custom {
    display: flex;
    justify-content: center;
    margin-bottom: -71px;
    position: relative;
    position: fixed;
    margin: -40px auto;
    right: 0;
    left: 0;
    z-index:999;
a {
  &:not(:last-child) {
    margin-right: 15px;
  }
}
    button {
      padding: 20px 50px;
      background: #fff;
      font-size: 20px;
      border-radius: 8px;
      box-shadow: 0px 3px 6px #00000029;
      border: 0;
      color: #707070;
      cursor: pointer;
      // opacity : 0.9;
      // font-weight: bold;
      
    }
  }
  .seconds {
    
    width: 100%;
    text-align: center;
    a {
      display: block;
      margin: 0px auto;
    }
    img {
      width: 390px !important;
    }
  }
  .header-theme {
    margin-right: 10px;
  }
  .custom-row {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 60px 0;
    .custom-col {
      p {
        line-height: 30px;
      }
    }
  }
  .footer-box {
    width: 30%;
    p{
      color : #fff;
      margin: 40px 0;
    }
  }
  .footerBorder {
    border-bottom: initial;
    padding-bottom: 0px;
  }
  .coin-left {
    left: -300% !important;
    top: -50% !important;
  }
  .hand-coin {
    position:absolute;
    left: 0;
    top: -5%;
  }
  .machine-coin {
    position:absolute;
    right: 0;
    top: 20%;
  }
  .loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid var(--chart-text); /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .apexcharts-menu {
    background: var(--modal-bg) !important;
    .apexcharts-menu-item {
      &:hover {
        background: transparent !important;
      }
    }
  }
  .inv-para {
    max-width: 375px;
  }
  .currencySelect {
    min-width: 120px;
    min-height: 50px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    background: var(--chart-text);
    border: 1px solid var(--chart-text);
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    option {
      color: #fff;
      padding: 5px 10px;
      span {
        font-size: 20px;
        margin-right: 10px;
        display: inline-block;
      }
    }
    &:focus {
      box-shadow: initial !important;
      outline: none;
    }
  }
  
  .custombtn {
    // background: linear-gradient(90deg, #327AE5 0%, #06283A 108.75%);
    background: var(--btn-toggle);
    border: 1px solid var(--btn-border);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 15px 22px;
    box-shadow: 0px 3px 6px #00000029;
    color: #F4AB00;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    width: initial;
    
    display: table;
    &:hover {
      text-decoration: none;
      background: #F4AB00;
      color: #fff;
    }
  }
  
  .trade-btn {
    margin-right: 30px;
    background: var(--btn-toggle);
    border: 1px solid var(--btn-border);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 15px 22px;
    box-shadow: 0px 3px 6px #00000029;
    color: #F4AB00;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    width: initial;
    margin: 0px auto;
    display: table;
    &:hover {
      text-decoration: none;
      background: #F4AB00;
      color: #fff;
    }
  }
  .yellow-text {
    color: #F4AB00;
  }
  .border-btn {
    margin-right: 30px;
    background: var(--btn-toggle);
    border: 1px solid var(--btn-border);
    border-radius: 2px;
    padding: 15px 22px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    color: #F4AB00;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    width: initial;
    margin: 0px auto;
    display: table;
    &:hover {
      text-decoration: none;
      background: #F4AB00;
      color: #fff;
    }
  }
  #add-recipient-button {
    color: #fff;
  }
  .expertBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    #confirm-expert-mode {
      margin:0 0 0 20px;
      background: var(--btn-toggle);
      border: 1px solid var(--btn-border);
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      color: #F4AB00;
      &:hover {
        background: #F4AB00;
border: 1px solid #FCBF31;
box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
border-radius: 2px;
color: #fff !important;
      }
    }
    .cancelBtn {
      background: var(--btn-toggle);
      border: 1px solid var(--btn-border);
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      color: #F4AB00;
      &:hover {
        background: #F4AB00;
border: 1px solid #FCBF31;
box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
border-radius: 2px;
color: #fff !important;
      }
    }
  }
  .warn-message {
    background: #FFE9D4;
    border: 1px solid #FFE9D4;
    border-radius: 5px;
    div {
      color: #E26D00;
    }
  }
  .explore-btn {
    margin-right: 30px;
    background: #fff;
    border-color: #1BFD9C;
    border-radius: 17px;
    padding: 15px 22px;
    box-shadow: 0px 3px 6px #00000029;
    color: #1BFD9C;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  #home-2 {
    .page-bg {
      padding-bottom: 0 !important;
      >div {
        padding-bottom: 0 !important;
      }
    }
  }
  .newsletter-box {
    position: relative;
  
  .subs-btn {
    background: transparent !important;
    border: initial;
    display: flex;
    align-items: center;
    z-index:1;
    
    svg {
      position: absolute;
      width: 15px;
      max-width: 100%;
      right: 20px;
      top: 10px;
      
    
      &.animated-spin {
        animation: spin 1s linear infinite;
      }
    }

    @keyframes spin {
      0% {transform: rotate(0deg);}
      100% {transform: rotate(360deg);}
    }
  }
}
  .pag-list {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      list-style-type: none;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--chart-text);
      color: #fff;
      cursor:pointer;
      &:not(:last-child) {
        margin-right: 5px;
      }
      &.active {
        background: var(--yellow-text);
      }
    }
  }
  .token-dismiss-button{
    background: #000;
  }
  .token-box {
    margin: 0px auto;
    svg {
      path {
        fill: #F4AB00;
      }
    }
  }
  .invest-sec {
    // background-image: url(${Investment.src}) !important;
    // background-position: left;
    // background-repeat: no-repeat;
    // background-size: contain;
    
    .custom-row {
      .custom-col {
        &:last-child {
          padding: 0 180px 0 0px;
        }
      }
    }
    .inv-text {
     
      p {
        color: var(--text-color);
        font-size: 18px;
      }
      .inv-list {
        li {
          list-style-type: none;
          font-weight: 500;
          font-size: 28px;
          line-height: 42px;
          color: var(--green-head);
          position: relative;
          padding: 10px 10px 10px 90px;
          display: flex;
          align-items: center;
          background: var(--list-bg);
          border: 1px solid var(--list-border);
          box-shadow: 0px 3px 7px rgba(182, 182, 182, 0.44);
          margin-top: 26px;
          img {
            position: absolute;
            left: 15px;
          }
          span {
            color: var(--user-head);
            font-weight: 700;
            font-size: 28px;
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--green-head);
          }
        }
      }
    }
  }
  .cards-row {
    justify-content: center;
    .custom-col {
      padding: 15px;
    }
  }
  .custom-card {
    background: var(--card-bg);
    box-shadow: 0px 0px 4px rgba(185, 185, 185, 0.25);
    border-radius: 3px;
    max-width: 550px;
    min-width: 550px;
    .img-box {
      height: 350px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .card-cont {
      padding: 20px;
      h3 {
        color: var(--chart-text);
        font-size: 28px;
        text-transform: capitalize;
        line-height: 40px;
      }
      p {
        color: var(--text-color) !important;
        margin: 14px 0;
      }
     
    }
  }
  .card-btns {
    display: flex;
    align-items: center;
    a {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  .mt-40 {
    margin-top: 40px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .banner-sec {
    background-image: url(${BG.src}) !important;
    background-size: cover;
    // background-position: center;
   
    position: relative;
    .coin-img {
      max-width: 100%;
      width: 100%;
    }
    h1 {
      font-size: 56px;
      padding: 110px 80px;
      color: var(--heading-color);
      margin-bottom: 40px !important;
      line-height: 90px;
      .custom-font {
        font-size: 24px !important;
      }
      .custom-font1 {
        font-size: 24px !important;
      }
      small {
          margin-top: 30px;
          display: inline-block;
        
      }
    }
  }
  .investBox {
    margin-top: 180px;
  }
  .loading-spinner {
    margin: 10px auto;
    animation: rotation 2s linear infinite;
    path {
      fill: white;
    }

    @keyframes rotation {
      0% {transform: rotate(0deg)}
      0% {transform: rotate(360deg)}
    }
  }

  .machine-img {
    max-width: 50%;
  }
  .customSubMenu {
    width: 30%;
    margin: 60px auto 30px;
    display: flex;
    justify-content: space-between;
    background: var(--tab-bg);
    box-shadow: 0px 0px 6px rgba(179, 179, 179, 0.25);
    border-radius: 6px;
    div {
      width: 30%; 
      margin-right: 0;
      div {
        width: 100%;
        // &:after {
        //   display : none !important;
        // }
      a {
        padding: 5px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        
      }
      }
    }
  }
  .chartHeader {
    background: var(--modal-bg);
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
border-radius: 5px;
width: 80%;
margin: 0px auto;
  }
  .Footer-list {
    li {
      a {
        padding: 0 20px;
      }
      &:not(:last-child) {
        border-right: 1px solid #fff;
      }
    }
  }
  
  .email-modal{
    width: 40%;
    margin: 0px auto;
    background: var(--modal-bg);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    padding: 30px 30px;
    .modal-header {
      text-align: center;
      img {
        width: 20%;
      }
      h1 {
        color: var(--chart-text);
        font-size: 20px;
        margin-top: 30px;
      }
    }
    .Cmodal-body {
      background: transparent;
      .email {
        padding: 20px 20px;
        margin-top: 30px;
        >div{
          margin-bottom: 0;
        }
        label {
          font-size: 14px;
          color: #AAAAAA;
          font-weight: 400;
          input {
            font-size: 14px;
            border-radius: 3px;
            background: var(--swap-input);
            color: (--input-color) !important;
            &:focus {
              box-shadow: initial !important;
              outline: none;
            }
          }
        }
      }
      button {
        margin-top: 50px;
        
      }
    }
  }
  #rfs-btn {
    color: var(--copy-text) !important;
    border: 1px solid var(--copy-text) !important;
    &:after {
      border-top: 5px solid var(--copy-text) !important;
    }
  }
  .coin1 {
    position: absolute;
    top: -85px;
    left: 140px;
  }
  .coin2 {
    position: absolute;
    top: 115px;
    left: 0px;
  }
  .coin3 {
    position: absolute;
    bottom: 0px;
    left: 100px;
  }
  .coin4 {
    position: absolute;
    bottom: -50px;
    left: 400px;
  }
  .coin5 {
    position: absolute;
    bottom: 0px;
    right: 400px;
  }
  .coin6 {
    position: absolute;
    bottom: 50px;
    right: 50px;
  }
  .coin7 {
    position: absolute;
    top: 170px;
    right: 0px;
  }
  .coin8 {
    position: absolute;
    top: -40px;
    right: 500px;
  }
  .crypto-modal {
    width: 40%;
    margin: 0px auto;
    background: var(--modal-bg);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    padding: 30px 30px;
    .modal-header {
      h1 {
        color: var(--chart-text);
      }
    }
    .Cmodal-body {
      .modal-box {
        background: var(--liq-box);
        box-shadow: 0px 0px 6px rgba(179, 179, 179, 0.25);
        border: 1px solid var(--liq-border);
        border-radius: 6px;
        padding: 20px 20px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        >div{
          margin-bottom: 0;
        }
        label {
          font-size: 14px;
          color: var(--copy-text);
          font-weight: 400;
          input {
            background: transparent;
            font-size: 30px;
            padding-left: 0;
            color: var(--copy-text);
            ::placeholder {
              color: var(--copy-text);
            }
            &:focus {
              box-shadow: initial !important;
              outline: none;
            }
          }
        }
      }
      button {
        margin-top: 50px;
      }
    }
  }
  #token-search-input::placeholder {
    color: #3580F1;
  }
  #token-search-input {
    color: var(--input-color);
    border: 1px solid var(--liq-border);
    box-shadow: initial;
    background: var(--swap-input) !important;
    ::placeholder {
      color: var(--input-color)
    }
  }
  .custom-country {
    ul {
      li {
        list-style: none;
        color: black;
      }
    }
    button {
      margin-top: 0 !important;
    }
  }
  .custom-currency {
    margin-top: 0px !important;
    #pair {
      font-size: 14px;
    }
  }
  .get-box {
    margin-top: 30px;
    p {
      color: #3580F1;
    }
  }
  .chart-text {
    color: var(--chart-text) !important;
  }
  .Arrow-Back {
    path {
      fill: var(--chart-text);
    }
  }
  .modal-body {
    background: var(--modal-bg);
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
margin: 5px;
  }
  .tool-icon {
    svg {
      path {
        fill : var(--chart-text);
      }
    }
  }
  .Arrow-D {
    path {
      fill : var(--chart-text);
    }
  }
  #remove-recipient-button {
    color: #fff !important;
  }
  .chart-q {
    svg {
      path {
        fill: var(--copy-text);
      }
    }
  }
  .Tx-input {
    input {
      background: var(--per-box);
      border: 1px solid var(--per-border);
      border-radius: 5px;
      color: #fff;
      &:focus {
        box-shadow: initial !important;
        outline: none;
      }
    }
    input::placeholder {
      color: #fff;
    }
  }
  .HelpBtns {
    button {
    background: var(--per-box);
    border: 1px solid var(--per-border);
    border-radius: 5px;
    color: #fff;
    height: 36px;
    margin-top: 0;
    }
    >div {
      background: var(--liquidity-box);
      border: 1px solid var(--liquidity-border);
      border-radius: 5px;
      padding-right: 8px;
      input {
        background: transparent;
        border: initial;
        color: var(--copy-text);
        box-shadow: initial !important;
        &:focus {
          box-shadow: initial !important;
        }
        
      }
      input::placeholder {
        color: var(--copy-text) !important;
      }
    }
  }
  .chart-btns {
    >div {
      border: 1px solid var(--chart-text);
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
      border-radius: 3px;
      background: transparent;
      button {
        color: #fff;
        border-radius: 0;
        height: 40px;
      }
    }
  }
  .chevron-custom {
    path {
      fill: var(--chart-text);
    }
  }
  #add-liquidity-select-tokena, #add-liquidity-select-tokenb {
    button {
      svg {
        path {
          fill : var(--chart-text)
        }
      }
      >div {
        background-color: var(--swap-input) !important;
        border: initial !important
        
      }
    }
  }
  .swapTabs {
    background: var(--tab-bg);
    box-shadow: 0px 0px 6px rgba(179, 179, 179, 0.25);
    border-radius: 6px;
    margin: 0px auto 50px;
    width: 100%;
    
    .swap-Btns {
      border-radius: 0;
    }
    >div {
      background-color: var(--tab-bg);
    }
  }
  .grey-text {
    color: #AAAAAA;
  }
  .swap-icon {
    svg {
      path {
        fill: var(--chart-text);
      }
    }
  }
  .swap-cont {
    .swap-col {
      width: 100%;
      // &:nth-child(2) {
      //   width: initial;
      // }
    }
  }
  .swap-box {
    background: var(--modal-bg);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    padding: 0px 20px 20px 20px;
  }
  #pair {
    color: var(--chart-text);
  }
  .currency-icons {
    svg {
      &:last-child {
        path {
          fill: var(--chart-text);
        }
      }
    }
  }
  #clickExchangeHelp {
    background-color: var(--help-bg) !important;
    border-radius: 3px;
  }
  .helpImage {
    img {
      top: 45px;
    }
  }
  .help-i {
      path {
        fill: 
        #3580F1;
      }
  }
  .anim-coin {
    position: relative;
    .animCoin1,  .animCoin3 { 
      position: absolute;
      width: 250px;
      animation-name:rotate;
      animation-duration: 20s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    .animCoin1 {
      top: 85px;
      left: 55px;
      
    }
     .animCoin2 { 
      top: 65px;
      left: 0px;
      position: absolute;
      width: 250px;
      animation-name:rotate1;
      animation-duration: 20s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      
    }
    .animCoin3 { 
      top: 40px;
      left: -50px;
    }
    @keyframes rotate{

      100%{
        transform:rotate(360deg);
      }
    }
    @keyframes rotate1{

      100%{
        transform:rotate(-360deg);
      }
    }
    @keyframes rotate{

      100%{
        transform:rotate(360deg);
      }
    }
   
  }
  .riskBtn {
    margin: 30px 0;
    button {
    background: linear-gradient(90.36deg, #3580F1 0.15%, rgba(0, 0, 0, 0) 96.75%);
    border: 1px solid #327AE5;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
    border-radius: 12px;
    height: 41px;
    text-transform: capitalize !important;
    width: 140px;
    font-size: 18px;
    }
  }
  .riskBtn .pancake-button--disabled {
    background: linear-gradient(90.36deg,#3580F1 0.15%,rgba(0,0,0,0) 96.75%) !important;
    border: 1px solid #327AE5;
  }
  .tole {
    display : flex;
    align-items : center;
    >div {
      &:first-child {
        margin-right: 50px;
      }
    }
  }
  // #swap-currency-input {
  //   position: relative;
  //   display: flex;
  //   align-items: center;
  // }
  // #swap-currency-output {
  //   position: relative;
  //   display: flex;
  //   align-items: center;
  // }
  .select-lang {
    margin-right: 25px;
  }
  .apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg {
      fill: var(--chart-text) !important;
  }
  .apexcharts-tooltip.apexcharts-theme-light {
    color: black;
  }
  .apexcharts-xaxis-texts-g {
    text:nth-child(2n+1) {
      // visibility: hidden;
    }

  }

  .apexcharts-toolbar {
    display: none !important;
    
    >div {
      color: var(--copy-text);
      svg {
        fill: var(--copy-text);
        path{
          
        }
      }
    }
  }
  .connect-btn {
    background: var(--btn-bg) !important;
    border: 1px solid var(--btn-border) !important;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25) !important;
    border-radius: 2px !important;
    height: 40px;
    color: #F4AB00;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.03em;
    padding: 0 16px;
    transition: 0.1s;
    &:hover {
      background: #F4AB00 !important;
      color: #fff;
      opacity:1 !important;
    }
  }
 .header-logo {
  width: 160px;
  height: 24px;
 }
 .select-coin {
  background: linear-gradient(89.76deg, #3580F1 0.17%, #041823 99.49%) !important;
 }
 .footer-logo {
  align-items: center !important;
 }
  @media(max-width: 1440px) {
    .coin3 {
      bottom: 20px;
      left: 50px;
    }
    .coin4 {
      bottom: -20px;
    }
    .coin5 {
      display: none;
    }
    .coin6 {
      right: 10px;
      bottom: 70px;
    }
    .coin8 {
      right: 300px;
    }
    .coin-left {
      display: none !important;
    }
    .banner-sec {
      h1 {
        font-size: 45px;
        small {
          font-size: 20px !important;
        }
      }
    }
   }
 

 @media(max-width: 767px) {
  .millionSec {
    text-align: center;
  }
  .inv-list li >div {
    span {
      font-size: 26px !important;
    }
    font-size: 22px;
  }
  .crypto-modal {
    width: 100%;
  }
  .customSubMenu {
    width: 95%;
    margin: 30px auto 30px;
    div {
      div a{
        font-size: 13px;
        padding: 5px 10px;
      }
    }
  }
  .custom-row {
    margin: 0;
  }
  .hand-coin, .machine-coin {
    position: initial;
  }
  .seconds-wrapper >div {
    margin: 30px 0;
  }
  
  .seconds {
    padding: 0 !important;
  }
  .investBox {
    margin-top: 0;
  }
  .inv-para {
    max-width: 100% !important;
    text-align: center;
  }
  .invest-sec .custom-row .custom-col:last-child, .cards-row .custom-col{
    padding: 0;
  }
  .custom-card{
    margin: 0px auto;
    min-width: initial;
    .img-box {
      height: 215px;
    }
  }
  .inv-list {
    padding: 0 15px;
  }
  .btns-custom {
    margin: -20px auto;
    button {
      padding: 5px 10px;
      font-size: 16px;
    }
  }
  .banner-sec {
    h1 {
      padding: 0px 40px;
      line-height: 50px;
    }
    .custombtn {
      margin: 60px auto 0;
    }
  }
  .right-image {
    display: none !important; 
  }
  #homepage-hero {
    display: none !important;
    span {
      right : 0 !important;
      display: none !important;
    }
  }
  #home-2 .page-bg {
    margin-top: 0px !important;
  }

  .custom-row.row1 {
    flex-direction: column-reverse !important;
    margin-top: 30px;
  }
  .card-btns{
    justify-content: center !important;
    .border-btn {
      display: initial;
      margin: 0;
    }
    .custombtn {
      margin-right: 15px;
    }
  }
  .custom-row {
    flex-wrap: wrap !important;
    .custom-col {
      text-align: center;
      width: 100% !important;
      padding-left: 0 !important;
      margin-bottom: 30px;
      .inner-deiv {
        justify-content: center;
      }
    }
  }
  .banner-sec {
    h1 {
      font-size: 36px;
      small {
        font-size: 20px !important;
      }
    }
  }
 }
 .coin-image {
  width: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  position: initial !important;
 }
 @media(min-width: 576px) {
  .br-block {
    display: block;
  }
  .footerCopyrightDesk {
    display: block !important;
    text-align: center;
  }
  .footerCopyrightMbl {
    display: none !important;
  }
 }
 @media(max-width:575px) {
  .seconds-wrapper {
    display : initial !important;
  }
  .logo-sec {
    flex-flow: column-reverse;
  }
  .footer-box {
    width: 100%;
  }
  .footerCopyrightDesk {
    display: none !important;
  }
  .footerCopyrightMbl {
    display: block;
    margin: 0px auto;
    text-align: center;
  }
}

`

export default GlobalStyle
