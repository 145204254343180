import { useMemo, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { Menu as UikitMenu, Flex, ThemeSwitcher } from '@pancakeswap/uikit'
import { useTranslation, languageList } from '@pancakeswap/localization'

import { NetworkSwitcher } from 'components/NetworkSwitcher'
import useTheme from 'hooks/useTheme'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'

import UserMenu from './UserMenu'
import { useMenuItems } from './hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'
// import { SettingsMode } from './GlobalSettings/types'

const Menu = (props) => {
  const { isDark, setTheme } = useTheme()
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  const [currentTheme, setCurrentTheme] = useState('dark')

  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])

  const themes = [
    {
      themeName: 'dark',
      headingColor: 'white',
      pageBgImage: 'var(--bg-image-dark)',
      textColor: 'white',
      btnBg: '#3580F1',
      checkBox: '#031118',
      checkBorder: '#3580f1',
      yellowText: '#fcbf31',
      footerInput: 'none',
      placeHolder: '#fff',
      inputColor: '#dedede',
      pancakeToggle: '#666666',
      btnColor: '#041823',
      modalBg: 'linear-gradient(180deg,#062b3f 0%,#000000 100%)',
      chartText: '#3580F1',
      swapInput: '#1e1e1e',
      copyText: '#fff',
      btnBorder: '#806926',
      userHead: '#3580F1',
      greenHead: '#fff',
      cardBg: '#062738',
      footerBg: 'linear-gradient(89.89deg,rgba(2,16,23,0.75) 1.07%,rgba(4,26,38,0.75) 99.92%)',
      tabBg: '#020E15',
      tabColor: '#fff',
      listBg: 'rgba(5,37,54,0.87)',
      listBorder: 'rgba(4, 26, 38, 0.62)',
      liquidityBox: '#1E1E1E',
      liquidityBorder: '#D5D5D5',
      perBox: 'linear-gradient(90.46deg,#3580F1 0.31%,rgba(6,43,63,0) 103.84%)',
      perBorder: '#327AE5',
      toggleBg: '#327AE5',
      liqBox: 'linear-gradient(89.9deg, #3580F1 0.93%, #09172D 99.92%)',
      liqBorder: '#327AE5',
      greyText: '#AAAAAA',
      helpBg: '#052B4C',
      copyRight: '#3580f1',
      selectCoin: '#1e1e1e',
      homeBannerDarkImage: 'inline-block',
      homeBannerLightImage: 'none',
    },

    {
      themeName: 'light',
      headingColor: 'rgb(33, 132, 127)',
      pageBgImage: 'var(--bg-image-light)',
      textColor: '#393939',
      btnBg: '#21847F',
      checkBox: '#fff',
      checkBorder: '#f4ab00',
      yellowText: '#fcbf31',
      footerInput: '#fff',
      placeHolder: '#b7b7b7',
      inputColor: '#b7b7b7',
      pancakeToggle: '#f0fffe',
      btnColor: '#fff',
      modalBg: 'linear-gradient(180deg, #FFFFFF 2.08%, #FCFFF4 48.96%, #FFFFFF 100%)',
      chartText: '#21847F',
      swapInput: '#F9F9F9',
      copyText: '#393939',
      btnBorder: '#F4AB00',
      userHead: '#393939',
      greenHead: '#21847F',
      cardBg: '#fff',
      footerBg: 'rgba(33, 132, 127, 0.71)',
      tabBg: '#fff',
      tabColor: '#f4ab00',
      listBg: '#FFFFFF',
      listBorder: 'rgba(241, 241, 241, 0.62)',
      liquidityBox: '#F0FFFE',
      liquidityBorder: '#D5D5D5',
      perBox: '#F4AB00',
      perBorder: '#FCBF31',
      toggleBg: '#F4AB00',
      liqBox: '#F3FFFE',
      liqBorder: '#327AE5',
      greyText: '#393939',
      helpBg: '#F4AB00',
      copyRight: '#fff',
      selectCoin: '#f9f9f9',
      homeBannerDarkImage: 'none',
      homeBannerLightImage: 'inline-block',
    },
  ]

  const applyTheme = (index) => {
    const root = document.documentElement
    root.style.setProperty('--current-theme', themes[index].themeName)
    root.style.setProperty('--heading-color', themes[index].headingColor)
    root.style.setProperty('--page-bg-image', themes[index].pageBgImage)
    root.style.setProperty('--text-color', themes[index].textColor)
    root.style.setProperty('--btn-color', themes[index].btnBg)
    root.style.setProperty('--checkbox-color', themes[index].checkBox)
    root.style.setProperty('--checkbox-border', themes[index].checkBorder)
    root.style.setProperty('--yellow-text', themes[index].yellowText)
    root.style.setProperty('--footer-input', themes[index].footerInput)
    root.style.setProperty('--place-holder', themes[index].placeHolder)
    root.style.setProperty('--input-color', themes[index].inputColor)
    root.style.setProperty('--pancake-toggle', themes[index].pancakeToggle)
    root.style.setProperty('--btn-toggle', themes[index].btnColor)
    root.style.setProperty('--modal-bg', themes[index].modalBg)
    root.style.setProperty('--chart-text', themes[index].chartText)
    root.style.setProperty('--swap-input', themes[index].swapInput)
    root.style.setProperty('--copy-text', themes[index].copyText)
    root.style.setProperty('--btn-border', themes[index].btnBorder)
    root.style.setProperty('--user-head', themes[index].userHead)
    root.style.setProperty('--green-head', themes[index].greenHead)
    root.style.setProperty('--card-bg', themes[index].cardBg)
    root.style.setProperty('--footer-bg', themes[index].footerBg)
    root.style.setProperty('--tab-bg', themes[index].tabBg)
    root.style.setProperty('--tab-color', themes[index].tabColor)
    root.style.setProperty('--list-bg', themes[index].listBg)
    root.style.setProperty('--list-border', themes[index].listBorder)
    root.style.setProperty('--liquidity-box', themes[index].liquidityBox)
    root.style.setProperty('--liquidity-border', themes[index].liquidityBorder)
    root.style.setProperty('--per-box', themes[index].perBox)
    root.style.setProperty('--per-border', themes[index].perBorder)
    root.style.setProperty('--togg-bg', themes[index].toggleBg)
    root.style.setProperty('--liq-box', themes[index].liqBox)
    root.style.setProperty('--liq-border', themes[index].liqBorder)
    root.style.setProperty('--grey-text', themes[index].greyText)
    root.style.setProperty('--help-bg', themes[index].helpBg)
    root.style.setProperty('--copy-right', themes[index].copyRight)
    root.style.setProperty('--select-coin', themes[index].selectCoin)
    root.style.setProperty('--home-banner-dark-image', themes[index].homeBannerDarkImage)
    root.style.setProperty('--home-banner-light-image', themes[index].homeBannerLightImage)
  }

  const switchTheme = (theme) => {
    setCurrentTheme(theme)
    if (theme == 'dark') {
      applyTheme(0)
    } else {
      applyTheme(1)
    }
  }

  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
        }}
        rightSide={
          <>
            <Flex className="header-theme">
              <ThemeSwitcher
                isDark={currentTheme == 'dark'}
                toggleTheme={() => {
                  if (currentTheme == 'dark') switchTheme('light')
                  else switchTheme('dark')
                }}
              />
            </Flex>
            <NetworkSwitcher />
            <UserMenu />
          </>
        }
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        cakePriceUsd={cakePriceUsd}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        buyCakeLabel={t('Buy CAKE')}
        {...props}
      />
    </>
  )
}

export default Menu
